<template>
  <main>
    <projects-component></projects-component>
  </main>
</template>
      
      <script>
import ProjectsComponent from "@/components/ProjectsComponent.vue";

export default {
  components: {
    ProjectsComponent,
  },
};
</script>
      
      <style scoped>
</style>