import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/views/HomePage.vue'; // Replace with your actual path

Vue.use( VueRouter );

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      title: "Simret's Portfolio home",
      metaTags: [
        // Define your meta tags for the home page
        { name: 'description', content: 'Explore Simret\'s portfolio showcasing projects, skills, and more.' },
        { property: 'og:title', content: "Simret's Portfolio" },
        { property: 'og:description', content: 'Explore Simret\'s portfolio showcasing projects, skills, and more.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://portfolio.simret.codes' },
        { property: 'og:image', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { property: 'og:image:secure_url', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { property: 'og:image:width', content: '2500' },
        { property: 'og:image:height', content: '1330' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: "Simret's Portfolio" },
        { name: 'twitter:description', content: 'Explore Simret\'s portfolio showcasing projects, skills, and more.' },
        { name: 'twitter:image', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { itemprop: 'name', content: "Simret's Portfolio" },
        { itemprop: 'description', content: 'Explore Simret\'s portfolio showcasing projects, skills, and more.' },
        { itemprop: 'image', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { name: 'author', content: 'Simret Paulos' },
        { name: 'robots', content: 'index,follow' },
        { property: 'fb:app_id', content: '1150780559522340' },
        { name: 'keywords', content: 'portfolio, projects, skills, contact, AWS, cloud computing, Vue.js, Simret Paulos' },
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '@/views/AboutPage.vue' ), // Lazy-loaded component
    meta: {
      title: 'About Me - Simret\'s Portfolio',
      metaTags: [
        // Define your meta tags for the about page
        { name: 'description', content: 'Learn more about Simret and her achievements.' },
        { property: 'og:title', content: 'About Me - Simret\'s Portfolio' },
        { property: 'og:description', content: 'Learn more about Simret and her achievements.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://portfolio.simret.codes/#about' },
        { property: 'og:image', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { property: 'og:image:secure_url', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { property: 'og:image:width', content: '2500' },
        { property: 'og:image:height', content: '1330' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'About Me - Simret\'s Portfolio' },
        { name: 'twitter:description', content: 'Learn more about Simret and her achievements.' },
        { name: 'twitter:image', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { itemprop: 'name', content: 'About Me - Simret\'s Portfolio' },
        { itemprop: 'description', content: 'Learn more about Simret and her achievements.' },
        { itemprop: 'image', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { name: 'author', content: 'Simret Paulos' },
        { name: 'robots', content: 'index,follow' },
        { property: 'fb:app_id', content: '1150780559522340' },
        { name: 'keywords', content: 'portfolio, projects, skills, contact, AWS, cloud computing, Vue.js, Simret Paulos' },
      ]
    }
  },
  // Add other routes as needed
];

const router = new VueRouter( {
  mode: 'history',
  routes
} );

router.beforeEach( ( to, from, next ) =>
{
  // Update title
  document.title = to.meta.title || "Simret's Portfolio";

  // Remove all existing meta tags
  Array.from( document.querySelectorAll( 'meta[name="description"], meta[property^="og:"], meta[name="twitter:"], meta[itemprop], meta[name="author"], meta[name="robots"], meta[property="fb:app_id"], meta[name="keywords"]' ) ).forEach( tag => tag.remove() );

  // Add new meta tags
  if ( to.meta.metaTags )
  {
    to.meta.metaTags.forEach( tag =>
    {
      let metaTag = document.createElement( 'meta' );
      Object.keys( tag ).forEach( key =>
      {
        metaTag.setAttribute( key, tag[key] );
      } );
      document.head.appendChild( metaTag );
    } );
  }

  next();
} );

export default router;
