import { render, staticRenderFns } from "./SkillComponent.vue?vue&type=template&id=5790d9a4&scoped=true"
import script from "./SkillComponent.vue?vue&type=script&lang=js"
export * from "./SkillComponent.vue?vue&type=script&lang=js"
import style0 from "./SkillComponent.vue?vue&type=style&index=0&id=5790d9a4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5790d9a4",
  null
  
)

export default component.exports