<template>
  <main>
    <about-component></about-component>
  </main>
</template>

<script>
import AboutComponent from "@/components/AboutComponent.vue";
import { defineComponent } from 'vue';
import { useMeta } from 'vue-meta';

export default defineComponent({
  name: "AboutPage",
  components: {
    AboutComponent,
  },
  setup() {
    const { meta } = useMeta();

    // Define meta tags dynamically
    meta.value = {
      title: 'About Me - Simret\'s Portfolio',
      metaTags: [
        { name: 'description', content: 'Learn more about Simret and her achievements.' },
        { property: 'og:title', content: 'About Me - Simret\'s Portfolio' },
        { property: 'og:description', content: 'Learn more about Simret and her achievements.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://portfolio.simret.codes/#about' },
        { property: 'og:image', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { property: 'og:image:secure_url', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { property: 'og:image:width', content: '2500' },
        { property: 'og:image:height', content: '1330' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'About Me - Simret\'s Portfolio' },
        { name: 'twitter:description', content: 'Learn more about Simret and her achievements.' },
        { name: 'twitter:image', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { itemprop: 'name', content: 'About Me - Simret\'s Portfolio' },
        { itemprop: 'description', content: 'Learn more about Simret and her achievements.' },
        { itemprop: 'image', content: 'https://portfolio.simret.codes/images/website_image.png' },
        { name: 'author', content: 'Simret Paulos' },
        { name: 'robots', content: 'index,follow' },
        { property: 'fb:app_id', content: '1150780559522340' },
        { name: 'keywords', content: 'portfolio, projects, skills, contact, AWS, cloud computing, Vue.js, Simret Paulos'   },
      ]
    };

    return {
      meta,
    };
  }
});
</script>

<style scoped>
main {
  width: 100%;
  display: grid;
  place-items: center;
}
</style>
