<template>
  <main>
    <Skill-Page> </Skill-Page>
  </main>
</template>
        
        <script>
import SkillPage from "@/components/SkillComponent.vue";

export default {
  components: {
    SkillPage,
  },
};
</script>
        
        <style scoped>
</style>